import FeatureImage from '../Single/FeatureImage';
import PostTitle from '../Single/PostTitle';
import PostExcerpt from '../Single/PostExcerpt';
import PostReadMore from '../Single/PostReadMore';
import PostMeta from '../Single/PostMeta';
import { classNames } from '../utils/functions';

const SideImage = ({ post, attributes, postClass }) => {
	const { thumbnail } = post;
	const { subLayout, isFImg } = attributes;

	const isLeftImg = 'left-image' === subLayout;
	const isRightImg = 'right-image' === subLayout;
	const className = classNames(postClass, 'apbSideImage', {
		'grid': isFImg && thumbnail.url,
		'leftImage': isLeftImg,
		'rightImage': isRightImg
	});

	return <article className={className}>
		{isLeftImg && <FeatureImage post={post} attributes={attributes} />}

		<div className='apbText'>
			<PostTitle post={post} attributes={attributes} />

			<PostMeta post={post} attributes={attributes} />

			<PostExcerpt post={post} attributes={attributes} />
			<PostReadMore post={post} attributes={attributes} />
		</div>

		{isRightImg && <FeatureImage post={post} attributes={attributes} />}
	</article>;
};
export default SideImage;