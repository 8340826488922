import MapPosts from './Layout/MapPosts';

const DisplayPosts = ({ posts, attributes }) => {
	const { layout, columns, sliderIsPage, sliderIsPrevNext } = attributes;
	const { desktop = 3, tablet = 2, mobile = 1 } = columns;

	const PostsLoop = () => <MapPosts posts={posts} attributes={attributes} />

	switch (layout) {
		case 'grid':
			return <div className={`apbGridPosts columns-${desktop} columns-tablet-${tablet} columns-mobile-${mobile}`}>
				<PostsLoop />
			</div>;

		case 'grid1':
			return <div className='apbGrid1Posts'>
				<PostsLoop />
			</div>;

		case 'masonry':
			return <div className={`apbMasonryPosts cols-${desktop} cols-tablet-${tablet} cols-mobile-${mobile}`}>
				<PostsLoop />
			</div>;

		case 'slider':
			return <div className='apbSliderPosts'>
				<div className='swiper-wrapper'>
					<PostsLoop />
				</div>

				{sliderIsPage && <div className='swiper-pagination'></div>}

				{sliderIsPrevNext && (
					<>
						<div className='swiper-button-prev'></div>
						<div className='swiper-button-next'></div>
					</>
				)}
			</div>;

		case 'ticker':
			return <div className='apbTickerPosts'>
				<div>
					<PostsLoop />
				</div>
			</div>;

		default:
			return null;
	}
}
export default DisplayPosts;