import FeatureImage from '../Single/FeatureImage';
import PostTitle from '../Single/PostTitle';
import PostExcerpt from '../Single/PostExcerpt';
import PostReadMore from '../Single/PostReadMore';
import PostMeta from '../Single/PostMeta';
import { classNames } from '../utils/functions';

const Default = ({ post, attributes, postClass }) => {
	const { subLayout } = attributes;

	const className = classNames(postClass, 'apbDefault');

	return <article className={className}>
		<FeatureImage post={post} attributes={attributes} />

		<div className='apbText'>
			<PostTitle post={post} attributes={attributes} />

			<PostMeta post={post} attributes={attributes} />

			{'title-meta' !== subLayout && <>
				<PostExcerpt post={post} attributes={attributes} />
				<PostReadMore post={post} attributes={attributes} />
			</>}
		</div>
	</article>;
};
export default Default;