import PostTitle from '../Single/PostTitle';
import PostExcerpt from '../Single/PostExcerpt';
import PostReadMore from '../Single/PostReadMore';
import PostMeta from '../Single/PostMeta';
import { classNames } from '../utils/functions';

const Overlay = ({ post, attributes, postClass }) => {
	const { title, thumbnail: { url: thumbUrl } } = post;
	const { subLayout } = attributes;

	const className = classNames(postClass, 'apbOverlay', {
		'apbOverlayHover': 'overlay-content-hover' === subLayout && thumbUrl,
		'apbOverlayBox': 'overlay-box' === subLayout || 'overlay-content-box' === subLayout,
		'apbOverlayHalfContent': 'overlay-half-content' === subLayout
	});

	return <article className={className}>
		{thumbUrl && <img src={thumbUrl} alt={title} />}

		<div className='apbText'>
			<PostTitle post={post} attributes={attributes} />

			<PostMeta post={post} attributes={attributes} />

			{'overlay-box' !== subLayout && 'overlay-half-content' !== subLayout && <>
				<PostExcerpt post={post} attributes={attributes} />
				<PostReadMore post={post} attributes={attributes} />
			</>}
		</div>
	</article>;
};

export default Overlay;